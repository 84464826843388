!((document, $) => {
  'use strict';

  /**
   * Header top menu moves to the main menu for desktop view
   * and revert back the previous position
   * on window resize or first load.
   */
  function topMenuPosition() {
    if ($(window).width() > 990) {
      $('.l-header-inner .block-menu-menu-header-top-menu').prependTo(
        '.l-header-inner .block-system-main-menu'
      );
    }
    else {
      $('.l-header-inner .block-menu-menu-header-top-menu').insertAfter(
        '.l-header-inner .block-system-header'
      );
    }
  }

  /**
   * Featured hero BTN
   * moves to after the wrapper block for mobile
   * and moves to previous position on desktop.
   */
  function featuredHeroBtnMovement() {
    if ($(window).width() < 768) {
      $('.front-featured-hero .featured-hero-btn').insertAfter(
        '.front .layout-front .front-featured-hero'
      );
    }
    else {
      $('.front .layout-front .featured-hero-btn').appendTo(
        '.front-featured-hero .block-content'
      );
    }
  }

  // Header top menu move to main menu
  $(document).ready(function() {
    topMenuPosition();
    featuredHeroBtnMovement();

    // Sticky header functionality
    var headerHeight = $('.l-header').outerHeight();
    $('body').css('padding-top', headerHeight);
    $(window).scroll(function() {
      if ($(document).scrollTop() > 1) {
        $('body').addClass('scrolled');
      }
      else {
        $('body').removeClass('scrolled');
      }
    });

    // hide the header on scroll down and fixed it again on scroll up
    var lastScrollTop = 0;
    $(window).scroll(function() {
      var scrollTop = $(this).scrollTop();
      if (scrollTop > 140) {
        $('.l-header').addClass('scroll');
        $('.l-header').removeClass('no-scroll');
      }
      if (lastScrollTop > scrollTop) {
        $('body').css('padding-top', headerHeight);
        $('.l-header').addClass('no-scroll');
        $('.l-header').removeClass('scroll');
      }
      lastScrollTop = scrollTop;
    });

    if ($('.update-post').length) {
      $('.update-post .views-row .post-body a').each(function() {
        if (
          $(this)
            .text()
            .includes('>>')
        ) {
          $(this).css({ display: 'none' });
        }
        if (
          $(this)
            .text()
            .includes('>')
        ) {
          $(this).css({ display: 'none' });
        }
      });
    }

    $(window).resize(function() {
      topMenuPosition();
      featuredHeroBtnMovement();
    });

    //make strech of the river block link
    $(document).on('click', '.front .strech_river', function(event) {
      if (!$(event.target).is('.strech_river .contextual-links li a')) {
        event.preventDefault();
        var url = $(this)
          .find('.block-content a')
          .attr('href');
        window.location = url;
      }
    });

    //frontpage general update type show FMR text

    if ($('.front .view-updates .General').length) {
      $('.front .view-updates .General .update-type').text('FMR');
    }

    //on tabs click go to main content

    var currentURL = window.location.pathname;
    var referrer = document.referrer;

    if (
      currentURL.includes('/Vision') ||
      currentURL.includes('/vision') ||
      currentURL.includes('/values') ||
      currentURL.includes('/equity')
    ) {
      const target = $('.content').offset().top - 180;
      window.scrollTo({
        top: target,
        behavior: 'instant'
      });
    }
    else if (
      currentURL.includes('/mission') &&
      (referrer.includes('values') ||
        referrer.includes('equity') ||
        referrer.includes('Vision') ||
        referrer.includes('vision'))
    ) {
      const target = $('.content').offset().top - 180;
      window.scrollTo({
        top: target,
        behavior: 'instant'
      });
    }

    //iframe height issue fix
    var iframes = document.querySelectorAll('iframe');
    iframes.forEach(element => {
      if (element.hasAttribute('height')) {
        let height = element.getAttribute('height');
        element.style.height = height;
      }
    });
  });
})(document, jQuery);
